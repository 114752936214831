import React, { useState, useEffect } from 'react';

import AdminPage from "../AdminPage/AdminPage"; // импорт страницы админа
import TrainerPage from '../TrainerPage/TrainerPage';
import Selector from '../Selector/Selector';
import TrainersList from '../TrainersList/TrainersList';
import FormAddTrainer from '../FormAddTrainer/FormAddTrainer'; // форма добавления тренера
import FormUpdateTrainer from '../FormUpdateTrainer/FormUpdateTrainer'; // форма редактирования тренера
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import StatisticsSuperAdmin from '../StatisticsSuperAdmin/StatisticsSuperAdmin'; // статистика по абонементам и тренерам(посещениям тренеровок)
import SettingsAbonements from '../SettingsAbonements/SettingsAbonements'; // настройка абонементов
import SettingsPaymentsToTrainer from '../SettingsPaymentsToTrainer/SettingsPaymentsToTrainer'; // настройка оплат за посещения
import AdminsList from '../AdminsList/AdminsList'; // Список админов
import FormAddAdmin from '../FormAddAdmin/FormAddAdmin'; // Форма добавления админа
import FormUpdateAdmin from '../FormUpdateAdmin/FormUpdateAdmin'; //Форма редактирования админа
import SignIn from '../SignIn/SignIn'; // Авторизация
import LoadingPage from '../LoadingPage/LoadingPage'; // страница загрузки авторизации

function SuperAdmin(
    {
        backgroundColor, // цвет фона
        textHeaderColor, // цвет титульника
        textColor, // цвет текста
        styleHeaderBoarderColor, // цвет полоски в хедере
        button, // кнопка ниже хедера
        section, // доп секция выбора меню
        api, // АПИ
        apiAuth, // АПИ Авторизации
        textButtonSubmit, // текст кнопки сабмита
        colorBackgroundForm, // цвет фона формы добавления
        stylePlaceHolder, // стиль плейсхолдеров инпутов
        styleButtonSubmit, // стиль кнопки сабмита
        styleButton, // стиль кнопок переключения AboutClientPage (абонемент, запись, активные тренировки)
        styleFooter, // стиль футер
        previousWeekButtonImg, // изображение стрелки перекл дней недели
        nextMonthButtonImg, // изображение стрелки перекл дней недели
        infoFlex,// давать ли информацию по абонементам Flex
        infoDark, // давать ли информацию по абонементам Dark
        imgUpdateTraining, // изображение кнопки редактирования тренировки
        imgRemoveTraining, // изображение кнопки удаления тренировки
        imgWA, // изображение кнопки мессенджер
        imgAddTraining, // изображение кнопки добавления тренировки
        imgTimeTable, // изображение кнопки расписания
        imgGroupeTrain, // изображение кнопки добавления групповой тренировки
        imgPersonalTrain, // изображение кнопки добавления персональной тренировки
        imgHomePage, // изображение кнопки главной страницы
        imgPhoneSearch, // картинка кнопки поиска по телефону
        imgNameSurnameSearch, // картинка кнопки поиска по телефону
        imgList, // изображение кнопки переклбчения на список в календаре
        imgAddClients, // изображение кнопки создания клиента
        imgCheckOneTimeTraining, // изображение кнопки проверки разовых тренировок
        imgAddOneTimeTraining, //картинка добавления абонемента для разовой тренировки
        imgRemoveOneTimeTraining, // картинка удаления разовой тренировки
        imgHystoryAbonement, // картинка истории абонементов
        isActiveTrainer, // для прокидывания с уровня ниже отображения стр управления тренерами
        isTypeSpace,
        //Суперадмин
        imgStatisticsButton, // кнопка статистики
        imgStatisticsAbonements, // кнопка статистики абонементов
        imgStatisticsVisits, //  кнопка статистики посещений
        imgChangeStatisticsAbonements, // кнопка смены статистики по абонементам с общ инф на список ии обратно
        imgSettingsAbonements, // кнопка настройки абонементов
        imgSettingsPaymentsToTrainer, // кнопка настройки оплат тренерам за посещения
        imgUpdate, // картинка кнопки редактирвоания админа
        imgRemove, // картинка кнопки удаления админа
        imgQRCode, // изображение кнопки qrcode
        styleBottomBorder, // стиль нижней рамки списка
        //styleButton, // цвет фона кнопок
    }

) {

    //Авторизация
    const [isAuthSuperAdmin, setIsAuthSuperAdmin] = useState(false);
    const [isPreloaderAuthWaiting, setIsPreloaderAuthWaiting] = useState(false); // true - показывать загрузочную страницу, пока проверяется автоиизация
    //Управление страницей отображения админа и суперадмина(может только суперадмин), необходимо тк страница суперадмина перегружена функционалом, он реже нужен, чем админ страница
    const [isLikeAdmin, setIsLikeAdmin] = useState(false);
    //Информация о тренерах и их тренировках
    const [isInformationAboutTrainersAndTrainings, setIsInformationAboutTrainersAndTrainings] = useState(false); // управление отображением информации о тренерах и их тренировках
    //управлять отображением футера на стр AdminPage
    const [isHideFooterForSuperAdmin, setIsHideFooterForSuperAdmin] = useState(false); // спрятать футер в компоненте админа
    //Работа с тренерами
    const [isSelectTrainers, setIsSelectTrainers] = useState(true); // селектор для определния работы с тренерами или клиентами
    const [trainers, setTrainers] = useState([]) // массив тренеров
    const [idSelectedTrainer, setIdSelectedTrainer] = useState('') // айди выбранного тренера
    const [selectedTrainer, setSelectedTrainer] = useState({}) // объект выбранного тренера
    const [isTrainerList, setIsTrainerList] = useState(true) // показывать список тренеров или нет
    //форма добавления тренера
    const [isOpenFormAddTrainer, setOpenFormAddTrainer] = useState(false); // открыть 
    //форма редактирования тренера
    const [isOpenFormUpdateTrainer, setOpenFormUpdateTrainer] = useState(false); // открыть 
    //инфотул
    const [isInfoToolOpen, setIsInfoToolOpen] = useState(false); // стейт для открытия информационного окна
    const [isTextMassageInfoTool, setTextMassageInfoTool] = useState(''); // текс в инфотул
    const [isOpenInfoToolRemoveTrainer, setIsOpenInfoToolRemoveTrainer] = useState(false); // инфотул подтверждения удаления тренера
    const [isOpenInfoToolRemoveAdmin, setIsOpenInfoToolRemoveAdmin] = useState(false); // инфотул подтверждения удаления тренера
    //Свитчер
    const [isSwitcher, setIsSwitcher] = useState(true); // показывать или нет переключения на клиентов и тренеров 
    const [isSwitchControlClient, setIsSwitchControlClient] = useState(false); // свитчер клиентов, показывать работа с ними или нет
    const [isSwitchControlTrainer, setIsSwitchControlTrainer] = useState(true); // свитчер тренеров, показывать работа с ними или нет
    const [isSwitchControlAdmin, setIsSwitchControlAdmin] = useState(false); // свитчер админ, показывать работа с ними или нет
    //Статистика
    const [isStatisticsSuperAdmin, setIsStatisticsSuperAdmin] = useState(false); // показывать статистику - true
    const [isStatisticsVisists, setIsStatisticsVisists] = useState(false); // показать статистику по тренировкам и посещениям
    const [isStatisticsAbonements, setIsStatisticsAbonements] = useState(false); // показать статистику по абонементам(страница подразделяется на Инфо и Список)
    const [isAbonementsInfo, setIsAbonementsInfo] = useState(true); // показать свобдную таблицу информации по абонементам
    //Настройка абонементов
    const [isSettingsAbonements, setIsSettingsAbonements] = useState(false); // настройки абонементов
    //Управлять отображением поиска
    const [ishideSearchForm, sethideSearchFormHide] = useState(false); // пробрасывается стейт, управления отображением инпута поиска, true - спрятать
    //Настройка оплат тренерам за посещения
    const [isSettingsPaymentsToTrainer, setIsSettingsPaymentsToTrainer] = useState(false); // настройка оплат тренерам за посещения
    const [infoPaymentsToTrainer, setInfoPaymentsToTrainer] = useState([]); // массив данных для рассчет оплат по посещениям ( передается в TrainerPage)
    //Админы
    const [isAdmins, setIsAdmins] = useState(false); // работа с админами или нет
    const [admins, setAdmins] = useState([]); // массив админов для отрисовки в AdminList
    const [idSelectedAdmin, setIdSelectedAdmin] = useState(''); // айди выбранного админа
    const [selectedAdmin, setSelectedAdmin] = useState({}); // объект выбранного админа
    const [isOpenFormAddAdmin, setIsOpenFormAddAdmin] = useState(false); // форма добавления админа
    const [isOpenFormUpdateAdmin, setIsOpenFormUpdateAdmin] = useState(false); // форма редактирования админа

    //Авторизация суперадминов
    function handleSignInSuperAdmin(data) {
        apiAuth.loginSuperAdmin(data)
            .then(() => {
                setIsAuthSuperAdmin(true);
            }).catch((err) => {
                setIsAuthSuperAdmin(false);
                setTextMassageInfoTool(`${err.message}`)
            })
    }


    //Проверить авторизацию суперадмина при отрисовке компонена
    useEffect(() => {
        setIsPreloaderAuthWaiting(true); // показать страницу загрузки авторизации
        api.getSelfSuperAdmin()
            .then((selfSuperAdmin) => {
                setIsAuthSuperAdmin(true);
                setIsPreloaderAuthWaiting(false); // спрятать страницу загрузки авторизации
            })
            .catch((error) => {
                setIsAuthSuperAdmin(false);
                setIsPreloaderAuthWaiting(false); // спрятать страницу загрузки авторизации
            });
    }, [])

    //ПЕРЕКЛЮЧАТЕЛИ-------------ПЕРЕКЛЮЧАТЕЛИ-------------ПЕРЕКЛЮЧАТЕЛИ-------------ПЕРЕКЛЮЧАТЕЛИ-------------ПЕРЕКЛЮЧАТЕЛИ-------------ПЕРЕКЛЮЧАТЕЛИ-------------

    //включить тренеров
    function onChangeSwitchControlTrainer() {
        setIsSwitchControlTrainer(true); // включить тренер свитчер
        setIsSwitchControlAdmin(false); // выкл админ свитчер
        setIsSwitchControlClient(false); // выкл клиент свитчер
    }

    //включить клиентов
    function onChangeSwitchControlClient() {
        setIsSwitchControlTrainer(false); // выключить свитчер
        setIsSwitchControlAdmin(false); // выкл админ свитчер
        setIsSwitchControlClient(true); // вкл клиент свитчер
    }

    //включить админов
    function onChangeSwitchControlAdmin() {
        setIsSwitchControlTrainer(false); // выключить свитчер
        setIsSwitchControlAdmin(true); // вкл админ свитчер
        setIsSwitchControlClient(false); // вкл клиент свитчер
    }

    //РАБОТА С ТРЕНЕРАМИ И ИХ ТРЕНИРОВКАМИ ------------------РАБОТА С ТРЕНЕРАМИ И ИХ ТРЕНИРОВКАМИ ------------------РАБОТА С ТРЕНЕРАМИ И ИХ ТРЕНИРОВКАМИ ------------------РАБОТА С ТРЕНЕРАМИ И ИХ ТРЕНИРОВКАМИ ------------------

    //Получить информация о оплате за посещения для тренеров (необходимо для рассчета ЗП)
    useEffect(() => {
        if (isInformationAboutTrainersAndTrainings) { // если открыта стр инф о тренере
            api.getPaymentToTheTrainer()
                .then(payments => { setInfoPaymentsToTrainer(payments.payments) })
                .catch(err => { console.log('произошла ошибка при получении инф по стоимости посещений') })
        }
    }, [isInformationAboutTrainersAndTrainings])

    //Клик на кнопку(на тренера)
    function clickInformationAboutTrainersAndTrainings() {
        setIsInformationAboutTrainersAndTrainings(true);
        setIsHideFooterForSuperAdmin(true); // спрятать футер у компонента админпейдж
        setIsSwitcher(false); // спрятать свитчер
        sethideSearchFormHide(true); // спрятать инпут поиска
    }

    //изменить стейт показа инф о тренерах и их тренировках (перехода нет, остаемся на главной) - клик на поиск, просиходит поиск и отображается результат на главной
    function changeStateIsTrainerAndTraining(state) {
        setIsInformationAboutTrainersAndTrainings(state)
    }

    //Клик на тренера для перехода на его страницу
    function handleActiveTrainerInfo(trainer) { //управлять отображением информации о тренере при клике на него
        setIdSelectedTrainer(trainer._id); // записать айди тренера
        setSelectedTrainer(trainer); // записать объект информации о тренер
        setIsInformationAboutTrainersAndTrainings(true); // + Открыть страницу отображения информации о тренере
        setIsHideFooterForSuperAdmin(true); // спрятать футер у компонента админпейдж
        setIsSwitcher(false); // показать свитчер
        setIsTrainerList(false); //спрятать список трененров
        sethideSearchFormHide(true); // спрятать инпут поиска
        setIsAdmins(false); // спрятать список админов
    }

    //Клик на кнопку добавления тренера(открыть форму)
    function handleOpenFormAddTrainer() {
        setOpenFormAddTrainer(true);
    }

    //Свернуть форму
    function handleCloseFormAddTrainer() {
        setOpenFormAddTrainer(false);
    }
    //
    //Добавить тренера
    function handleAddTrainer(data) {
        api.createTrainer(data)
            .then((trainer) => {
                setOpenFormAddTrainer(false);
                setIsInfoToolOpen(true);
                setTextMassageInfoTool('Тренер добавлен')
                const updatedTrainers = [...trainers, {
                    _id: trainer._id,
                    name: trainer.name,
                    surname: trainer.surname,
                    phoneNumber: trainer.phoneNumber
                }];
                setTrainers(updatedTrainers);// добавить нового тренера в массив
            })
            .catch((err) => {
                setIsInfoToolOpen(true);
                setTextMassageInfoTool(`Ошибка: ${err.message}`)
            })
    }

    //клик на кнопку редактирования тренера 
    function clickUpdateTrainer() {
        setOpenFormUpdateTrainer(true);
    }

    //Клик на кнопку удаления тренера
    function clickRemoveTrainer() {
        setIsOpenInfoToolRemoveTrainer(true);
    }

    //Удалить тренера по сабмиту
    function handleRemoveTrainer() {
        api.removeTrainer(selectedTrainer._id)
            .then(() => {
                setIsOpenInfoToolRemoveTrainer(false);
                setIsInfoToolOpen(true);
                setTextMassageInfoTool('Тренер удален')
                const updateTrainers = trainers.filter(trainer => trainer._id !== selectedTrainer._id);
                setTrainers(updateTrainers);
                //Вернуться на главную
                setIsHideFooterForSuperAdmin(false); // спрятать футер у компонента админпейдж -true, false - показать
                setIsInformationAboutTrainersAndTrainings(false); // свернуть инфо о тренерах
                setIsSwitcher(true); // показать свитчер
                sethideSearchFormHide(false); // показать инпут поиска
                setIsStatisticsSuperAdmin(false); // свернуть компонент со статистикой, если он открыт
                setIsSettingsAbonements(false); // свернуть настройки
                setIsSettingsPaymentsToTrainer(false); // свернуть настройки оплаты посещений тренеров
            })
            .catch(err => {
                setIsOpenInfoToolRemoveTrainer(false);
                setIsInfoToolOpen(true);
                setTextMassageInfoTool('ошибка при удалении')
            })
    }

    //свернуть форму редактирования тренера 
    function handleCloseFormUpdateTrainer() {
        setOpenFormUpdateTrainer(false);
    }

    //Сабмит редактирования тренера
    function handleUpdateTrainer(data) {
        api.updateTrainer(idSelectedTrainer, data)
            .then(() => {
                setOpenFormUpdateTrainer(false);
                setIsInfoToolOpen(true);
                setTextMassageInfoTool('Тренер отредактирован')
            }).catch((err) => {
                setIsInfoToolOpen(true);
                setTextMassageInfoTool(`Ошибка: ${err.message}`)
            });
    }

    //наполнить массив тренеров(в AdminPage) 
    function changeTrainers(array) {
        setTrainers(array);
        setIsTrainerList(true);
    }

    //ПАНЕЛЬ УПРАВЛЕНИЯ---------------------ПАНЕЛЬ УПРАВЛЕНИЯ---------------------ПАНЕЛЬ УПРАВЛЕНИЯ---------------------------------

    //Кликнуть на кнопку "На главную"
    function handleClickOnMainPage() {
        setIsHideFooterForSuperAdmin(false); // спрятать футер у компонента админпейдж -true, false - показать
        setIsInformationAboutTrainersAndTrainings(false); // свернуть инфо о тренерах
        setIsSwitcher(true); // показать свитчер
        sethideSearchFormHide(false); // показать инпут поиска
        setIsStatisticsSuperAdmin(false); // свернуть компонент со статистикой, если он открыт
        setIsSettingsAbonements(false); // свернуть настройки
        setIsSettingsPaymentsToTrainer(false); // свернуть настройки оплаты посещений тренеров
    }

    //СТАТИСТИКА---------------------СТАТИСТИКА---------------------СТАТИСТИКА---------------------СТАТИСТИКА---------------------СТАТИСТИКА---------------------

    //Открыть статистику(основная)
    function clickStatisticsButton() {
        setIsStatisticsSuperAdmin(true);
        sethideSearchFormHide(true); // спрятать инпут поиска
        setIsHideFooterForSuperAdmin(true); // спрятать футер АдминПейдд, тк у Статистики свой футер
        setIsTrainerList(false); //спрятать список клиентов, если он был открыт
        setIsSwitcher(false); // спрятать свитчер
    }

    //Клик на статистику абонементов
    function clickStatisticsAbonements() {
        setIsStatisticsAbonements(true); // открыть статистику абонементов
        setIsStatisticsVisists(false); // свернуть статистику посещений
        setIsSettingsAbonements(false); // свернуть настройки абонементов, если они открыты
        setIsSettingsPaymentsToTrainer(false); // свернуть настройки оплаты посещений тренеров если открыты
    }

    //Клик на статистику посещений
    function clickStatisticsVisits() {
        setIsStatisticsVisists(true); // открыть статистику посещений
        setIsStatisticsAbonements(false); // свернуть статистику абонементов
        setIsSettingsAbonements(false); // свернуть настройки абонементов, если они открыты
        setIsSettingsPaymentsToTrainer(false); // свернуть настройки оплаты посещений тренеров если открыты
    }

    //Менять статистику абонементов с общ инф на список абонементов
    function clickChangeStatisticsAbonements() {
        if (isAbonementsInfo) {
            setIsAbonementsInfo(false)
        } else {
            setIsAbonementsInfo(true)
        }
    }

    //Настройки абонементов 
    function clickSettingsAbonements() {
        setIsSettingsAbonements(true);
        setIsAbonementsInfo(false); // если открыта инф об абонементах - свернуть
        setIsStatisticsVisists(false); // свернуть ин о посещениях
        setIsStatisticsAbonements(false); // свернуть статистику по абонементам
        setIsSettingsPaymentsToTrainer(false); // свернуть настройку оплат за посещение
    }

    //Настройка оплат за посещения
    function clicSettingsPaymentsToTrainer() {
        setIsSettingsAbonements(false); // свернуть настройку абонементов
        setIsSettingsPaymentsToTrainer(true); // открыть настройку оплат за посещение
    }

    //При клике на кнопку просмотра статистике делать активной для первой отрисовки статистику по абонементам
    useEffect(() => {
        if (isStatisticsSuperAdmin) {
            setIsStatisticsAbonements(true)
        }
    }, [isStatisticsSuperAdmin])

    //Инфотул-----------------Инфотул-----------------Инфотул-----------------Инфотул-----------------Инфотул-----------------Инфотул
    //Свернуть инфотул
    function closeInfotool() {
        setIsInfoToolOpen(false);
    }

    //Свернуть инфотул подтверждения удаления тренера
    function closeInfoToolRemoveTrainer() {
        setIsOpenInfoToolRemoveTrainer(false);
    }

    //АДМИНЫ--------------------------------АДМИНЫ--------------------------------
    //Выбрать админа(кликнуть из списка)
    function handleOpenActiveAdmin(admin) {
        setIdSelectedAdmin(admin._id); // записать айди админа
        setSelectedAdmin(admin); // записать объект информации о админа
    }

    //Добавить админа 
    //клик на кнопку добавления
    function handleOpenFormAddAdmin() {
        setIsOpenFormAddAdmin(true); // открыть форму
    }

    //Свернуть форму добавления админа
    function handleCloseFormAddAdmin() {
        setIsOpenFormAddAdmin(false); // открыть форму
    }

    //сабмит формы добавления админа
    function handleAddAdmin(data) {
        api.createAdmin(data)
            .then((admin) => {
                setIsOpenFormAddAdmin(false);
                setIsInfoToolOpen(true);
                setTextMassageInfoTool('Админ добавлен')
                const updatedAdmins = [...admins, {
                    _id: admin._id,
                    name: admin.name,
                    surname: admin.surname,
                    phoneNumber: admin.phoneNumber
                }];
                setAdmins(updatedAdmins);// добавить нового админа в массив
            }).catch((error) => {
                setIsInfoToolOpen(true);
                if (error.message.includes('E11000')) {
                    setTextMassageInfoTool('Администратор с таким телефоном уже заведен')
                } else {
                    setTextMassageInfoTool(`Произошла ошибка при добавлении : ${error.message}`)
                }
            });
    }

    //Открыть форму редактирования админа
    function handleOpenFormUpdateAdmin() {
        setIsOpenFormUpdateAdmin(true);
    }

    //Свернуть форму редактирования админа
    function handleCloseFormUpdateAdmin() {
        setIsOpenFormUpdateAdmin(false);
    }

    //Сабмит редактирования админа
    function handleUpdateAdmin(data) {
        api.updateAdmin(idSelectedAdmin, data)
            .then(() => {
                setIsOpenFormUpdateAdmin(false);
                setIsInfoToolOpen(true);
                setTextMassageInfoTool('Админ отредактирован')
            }).catch((error) => {
                setIsInfoToolOpen(true);
                setTextMassageInfoTool(`Произошла ошибка ${error.message}`)
            });
    }

    //Открыть инфотул подтверждения удаления админа
    function handleOpenFormRemoveAdmin() {
        setIsOpenInfoToolRemoveAdmin(true);
    }

    //свернуть инфотул подтверждения удаления админа
    function closeInfoToolRemoveAdmin() {
        setIsOpenInfoToolRemoveAdmin(false);
    }

    //Сабмит подтверждения удаления админа
    function handleRemoveAdmin() {
        api.removeAdmin(idSelectedAdmin)
            .then(() => {
                setIsOpenInfoToolRemoveAdmin(false);
                setIsInfoToolOpen(true);
                setTextMassageInfoTool('Админ удален');
                const updatedAdmins = admins.filter(admin => admin._id !== idSelectedAdmin);
                setAdmins(updatedAdmins);// убрать удаленного админа из массива
            })
            .catch(() => {
                setIsOpenInfoToolRemoveAdmin(false);
                setIsInfoToolOpen(true);
                setTextMassageInfoTool('Произошла ошибка при удалении')
            })
    }

    //Сворачивать инфотул автоматически
    useEffect(() => {
        if (isInfoToolOpen) {
            setTimeout(() => { setIsInfoToolOpen(false) }, 3000)
        }
    }, [isInfoToolOpen]);

    //Получить массив админов при открытии списка
    useEffect(() => {
        if (isSwitchControlAdmin) {
            api.getAdmins()
                .then((admins) => { setAdmins(admins.admins) })
                .catch(err => console.log(err))
        }
    }, [isSwitchControlAdmin])

    function handleSetIsLikeAdmin(state) {
        setIsLikeAdmin(state)
    }

    return (
        isPreloaderAuthWaiting ?
            <LoadingPage backgroundColor={backgroundColor} />
            :
            isAuthSuperAdmin ? // если авторизован
                <AdminPage
                    // компоненты суперадмина, которыми удобно управлять из суперадмина (кроме свитчера и списка клиентов)
                    handleSetIsLikeAdmin={handleSetIsLikeAdmin}
                    isLikeAdmin={isLikeAdmin}
                    isSuperAdmin={true} //Возможность добавлять группы только у суперадмина
                    childComponentSuperAdmin={
                        <>
                            {/* информация о тренерах и их тренировках */}
                            {isInformationAboutTrainersAndTrainings ?
                                < TrainerPage
                                    backgroundColor={backgroundColor} // цвет фона
                                    textHeaderColor={textColor} // цвет текста
                                    api={api} // АПИ
                                    colorBackgroundForm={backgroundColor} // цвет фона формы добавления
                                    previousWeekButtonImg={previousWeekButtonImg} // изображение стрелки перекл дней недели
                                    nextMonthButtonImg={nextMonthButtonImg} // изображение стрелки перекл дней недели
                                    infoFlex={infoFlex} // давать ли информацию по абонементам Flex
                                    infoDark={infoDark} // давать ли информацию по абонементам Dark
                                    imgList={imgList} // картинка кнопки тренировок(списка)
                                    imgTimeTable={imgTimeTable}// картинка кнопки каhandleUpdateTrainerлендаря
                                    isSuperAdmin={true} // если монтируется в страницу суперадмина - true
                                    imgHomePage={imgHomePage} // кнопка главной страницы ( только у суперадмина)
                                    handleClickOnAdminPage={handleClickOnMainPage} // возврат на главную ( у суперадмина)
                                    idTrainerFromSuperAdmin={idSelectedTrainer} // айди тренера (из суперадмина)
                                    infoPaymentsToTrainer={infoPaymentsToTrainer} // данные по стоимости посещений для рассчета
                                    styleText={textColor}
                                />
                                : <></>}
                            {/* ФОРМА ДОБАВЛЕНИЯ ТРЕНЕРА */}
                            {isOpenFormAddTrainer ?
                                <FormAddTrainer // форма добавления 
                                    isOpen={isOpenFormAddTrainer} // стейт управления открытия формы добавления 
                                    isClose={handleCloseFormAddTrainer} // клик на кнопку для сворачивания
                                    onSubmit={handleAddTrainer} // передать функция добавления 
                                    textButtonSubmit={'Добавить'}
                                    colorBackground={colorBackgroundForm} // цвет фона
                                    styleButtonSubmit={styleButtonSubmit} // стиль кнопки сабмита
                                />
                                : <></>}
                            {/* ФОРМА РЕДАКТИРОВАНИЯ ТРЕНЕРА */}
                            {isOpenFormUpdateTrainer ?
                                <FormUpdateTrainer // форма редактирования 
                                    isOpen={isOpenFormUpdateTrainer} // стейт управления открытия формы редактирования 
                                    isClose={handleCloseFormUpdateTrainer} // клик на кнопку для сворачивания
                                    onSubmit={handleUpdateTrainer} // передать функция редактирования
                                    textButtonSubmit={'Редактировать'}
                                    colorBackground={colorBackgroundForm} // цвет фона
                                    styleButtonSubmit={styleButtonSubmit} // стиль кнопки сабмита
                                    trainer={selectedTrainer} //объект с данными тренера
                                />
                                : <></>}
                            {/* ФОРМА ДОБАВЛЕНИЯ АДМИНА */}
                            {isOpenFormAddAdmin ?
                                <FormAddAdmin
                                    isOpen={isOpenFormAddAdmin} // стейт управления открытия формы добавления 
                                    isClose={handleCloseFormAddAdmin} // клик на кнопку для сворачивания
                                    onSubmit={handleAddAdmin} // передать функция добавления 
                                    textButtonSubmit={'Добавить'}
                                    colorBackground={colorBackgroundForm} // цвет фона
                                    styleButtonSubmit={styleButtonSubmit} // стиль кнопки сабмита
                                />
                                : <></>
                            }
                            {/* ФОРМА РЕДАКТИРОВАНИЯ АДМИНА */}
                            {isOpenFormUpdateAdmin ?
                                <FormUpdateAdmin
                                    isOpen={isOpenFormUpdateAdmin} // стейт управления открытия формы редактирования 
                                    isClose={handleCloseFormUpdateAdmin} // клик на кнопку для сворачивания
                                    onSubmit={handleUpdateAdmin} // передать функция редактирования
                                    textButtonSubmit={'Редактировать'}
                                    colorBackground={colorBackgroundForm} // цвет фона
                                    styleButtonSubmit={styleButtonSubmit} // стиль кнопки сабмита
                                    admin={selectedAdmin} //объект с данными админа
                                />
                                : <></>
                            }
                            {/* ИНФОТУЛ */}
                            {isInfoToolOpen ?
                                <InfoTooltip
                                    isOpen={isInfoToolOpen}
                                    isClose={closeInfotool}
                                    text={isTextMassageInfoTool}
                                    showButton={false}
                                    backgoundColor={colorBackgroundForm} />
                                : <></>}
                            {/* ИНФОТУЛ ПОДТВЕРЖДЕНИЯ УДАЛЕНИЯ ТРЕНЕРА */}
                            {isOpenInfoToolRemoveTrainer ?
                                <InfoTooltip
                                    isOpen={isOpenInfoToolRemoveTrainer}
                                    isClose={closeInfoToolRemoveTrainer}
                                    text={`Удалить тренера ${selectedTrainer.name} ${selectedTrainer.surname}`}
                                    showButton={true}
                                    clickButtonOne={handleRemoveTrainer} // дейсвтие при клике
                                    clickButtonTwo={closeInfoToolRemoveTrainer} // дейсвтие при клике
                                    textButtonOne={'Да'} // текст кнопки
                                    textButtonTwo={'Нет'} // текст кнопки
                                    backgoundColor={colorBackgroundForm} />
                                : <></>}

                            {/* ИНФОТУЛ ПОДТВЕРЖДЕНИЯ УДАЛЕНИЯ АДМИНА */}
                            {isOpenInfoToolRemoveAdmin ?
                                <InfoTooltip
                                    isOpen={isOpenInfoToolRemoveAdmin}
                                    isClose={closeInfoToolRemoveAdmin}
                                    text={`Удалить тренера ${selectedAdmin.name} ${selectedAdmin.surname}`}
                                    showButton={true}
                                    clickButtonOne={handleRemoveAdmin} // дейсвтие при клике
                                    clickButtonTwo={closeInfoToolRemoveAdmin} // дейсвтие при клике
                                    textButtonOne={'Да'} // текст кнопки
                                    textButtonTwo={'Нет'} // текст кнопки
                                    backgoundColor={colorBackgroundForm} />
                                : <></>}
                            {/* СТАТИСТИКА */}
                            {isStatisticsSuperAdmin ?
                                <StatisticsSuperAdmin
                                    api={api}
                                    isStatisticsVisists={isStatisticsVisists} // показать статистику по посещениям
                                    isStatisticsAbonements={isStatisticsAbonements} // показать статистику по абонементам
                                    isAbonementsInfo={isAbonementsInfo} // true - показ общ статистики false - списка абонементов
                                    styleText={textColor} // стиль текста
                                    styleBottomBorder={styleBottomBorder} // стиль нижней рамки списка
                                    isSettingsAbonements={isSettingsAbonements} // следить за открытием настроек абонементов
                                    isSettingsPaymentsToTrainer={isSettingsPaymentsToTrainer} // следить за открытием настроек оплат за посещение
                                />
                                : <></>}
                            {/* НАСТРОЙКА СТОИМОСТИ АБОНЕМЕНТОВ */}
                            {isSettingsAbonements ?
                                <SettingsAbonements
                                    api={api}
                                    colorBackground={backgroundColor}
                                    styleText={textColor}
                                />
                                : <></>}
                            {/* НАСТРОЙКА ОПЛАТ ЗА ПОСЕЩЕНИЯ */}
                            {isSettingsPaymentsToTrainer ?
                                <SettingsPaymentsToTrainer
                                    api={api}
                                    styleText={textColor}
                                    colorBackground={backgroundColor}
                                    colorButtonSubmit={styleButtonSubmit}
                                />
                                : <></>}
                        </>

                    }
                    // ПЕРЕКЛЮЧАТЕЛЬ ДЛЯ СУПЕРАДМИНА НА РАБОТУ ЛИБО С КЛИЕНТАМИ, ЛИБО С ТРЕНЕРАМИ, ЛИБОА АДМИНИСТРАТОРАМИ
                    switcherSuperAdmin=
                    {isSwitcher ?
                        <div className='super-admin__switcher-container'>
                            {/* Селектор тренера: только для отображения, меняется в зависимости от селектора клиентов и зависит от него */}
                            < Selector
                                nameOne={'Тренеры'}
                                handleOnClickOne={onChangeSwitchControlTrainer}
                                switchControl={isSwitchControlTrainer} // внешний контроль отображения, true -вкл
                                onlyOn={true} // true - не возвращать селектор при повторном клике, можно только включать
                                styleSelector={'formadd__checkbox_super-admin'}
                            />
                            {/* Селектор клиентов */}
                            < Selector
                                nameOne={'Клиенты'}
                                handleOnClickOne={onChangeSwitchControlClient}
                                switchControl={isSwitchControlClient} // внешний контроль отображения, true -вкл
                                onlyOn={true} // true - не возвращать селектор при повторном клике, можно только включать
                                styleSelector={'formadd__checkbox_super-admin'}
                            />
                            {/* Селектор админов */}
                            < Selector
                                nameOne={'Админы'}
                                handleOnClickOne={onChangeSwitchControlAdmin}
                                switchControl={isSwitchControlAdmin} // внешний контроль отображения, true -вкл
                                onlyOn={true} // true - не возвращать селектор при повторном клике, можно только включать
                                styleSelector={'formadd__checkbox_super-admin'}
                            />
                        </div>
                        : <></>}

                    // ДОПОЛНИТЕЛЬНЫЕ КНОПКИ В ФУТЕРЕ ДЛЯ СУПЕРАДМИНА (РАБОТА С ТРЕНЕРОМ И СТАТИСТИКА)
                    childButtonsFooterSuperAdmin={
                        <>
                            {/* Перейти на главную */}
                            < button className={`admin-page__list-comand-button ${backgroundColor}`}
                                onClick={handleClickOnMainPage} >
                                <img className='admin-page__button-img' src={imgHomePage} alt='imgHomePage' />
                            </button >
                            {/* КНОПКИ РАБОТЫ С ТРЕНЕРОМ */}
                            {isInformationAboutTrainersAndTrainings ?
                                <>
                                    {/* Редактировать(тренера или клиента) */}
                                    < button className={`admin-page__list-comand-button ${backgroundColor}`}
                                        onClick={clickUpdateTrainer} >
                                        <img className='admin-page__button-img' src={imgUpdateTraining} alt='imgRedact' />
                                    </button >
                                    {/* Удалить (тренера или клиента) */}
                                    < button className={`admin-page__list-comand-button ${backgroundColor}`}
                                        onClick={clickRemoveTrainer}>
                                        <img className='admin-page__button-img' src={imgRemoveTraining} alt='imgRemove' />
                                    </button >
                                </>
                                : <></>}
                            {/* КНОПКИ СТАТИСТИКИ И НАСТРОЙКИ АБОНЕМЕНТОВ */}
                            {isStatisticsSuperAdmin ?
                                <>
                                    {/* КНОПКА ПРОСМОТР СТАТИСТИКИ ПО АБОНЕМЕНТАМ  */}
                                    {isStatisticsAbonements ?
                                        // Кнопка смены просмотра общей статистики на список абонементов
                                        < button className={`admin-page__list-comand-button ${backgroundColor}`}
                                            onClick={clickChangeStatisticsAbonements} >
                                            <img className='admin-page__button-img' src={imgChangeStatisticsAbonements} alt='imgChangeStatisticsAbonements' />
                                        </button >
                                        :
                                        < button className={`admin-page__list-comand-button ${backgroundColor}`}
                                            onClick={clickStatisticsAbonements} >
                                            <img className='admin-page__button-img' src={imgStatisticsAbonements} alt='imgStatisticsAbonements' />
                                        </button >}

                                    {/* ПРОСМОТР СТАТИСТИКИ ПО ПОСЕЩЕНИЯМ */}
                                    {isStatisticsVisists ?
                                        <></>
                                        :
                                        < button className={`admin-page__list-comand-button ${backgroundColor}`}
                                            onClick={clickStatisticsVisits} >
                                            <img className='admin-page__button-img' src={imgStatisticsVisits} alt='imgStatisticsVisits' />
                                        </button >}
                                    {/* НАСТРОЙКИ АБОНЕМЕНТОВ */}
                                    {isSettingsAbonements ? <></> :
                                        < button className={`admin-page__list-comand-button ${backgroundColor}`}
                                            onClick={clickSettingsAbonements} >
                                            <img className='admin-page__button-img' src={imgSettingsAbonements} alt='imgSettingsAbonements' />
                                        </button >}
                                    {/* НАСТРОЙКИ ОПЛАТ ТРЕНЕРАМ ЗА ПОСЕЩЕНИЕ */}
                                    {!isSettingsPaymentsToTrainer && isSettingsAbonements ?
                                        < button className={`admin-page__list-comand-button ${backgroundColor}`}
                                            onClick={clicSettingsPaymentsToTrainer} >
                                            <img className='admin-page__button-img' src={imgSettingsPaymentsToTrainer} alt='imgSettingsPaymentsToTrainer' />
                                        </button >
                                        : <></>
                                    }
                                </>
                                : <></>}
                        </>
                    }
                    //КНОПКА ДОБАВЛЕПНИЯ ТРЕНЕРА
                    buttonAddTrainer={
                        < button
                            className={`admin-page__list-comand-button ${backgroundColor}`}
                            onClick={handleOpenFormAddTrainer} >
                            <img className='admin-page__button-img' src={imgAddClients} alt='imgAddClients' />
                        </button >
                    }
                    //КНОПКА ПРОСМОТРА СТАТИСТИКИ ПО АБОНЕМЕНТОВ
                    buttonStatistics={
                        < button
                            className={`admin-page__list-comand-button ${backgroundColor}`}
                            onClick={clickStatisticsButton} >
                            <img className='admin-page__button-img' src={imgStatisticsButton} alt='imgStatistics' />
                        </button >
                    }
                    //СПИСОК ТРЕНЕРОВ
                    trainersList={
                        isTrainerList ?
                            <TrainersList
                                trainers={trainers}
                                api={api}
                                handleActiveTrainerInfo={handleActiveTrainerInfo} //управлять отображением информации о тренере при клике на него
                                styleText={textColor} // стиль текста
                            />
                            : <></>
                    }
                    // СПИСОК АДМИНОВ
                    adminsList={
                        isSwitchControlAdmin ?
                            <AdminsList
                                admins={admins}
                                idSelectedAdmin={idSelectedAdmin} // айди выбранного админа для отрисовки кнопок редактирования и удаления
                                api={api}
                                handleActiveAdminInfo={handleOpenActiveAdmin} //управлять отображением информации о тренере при клике на него
                                handleOpenFormUpdateAdmin={handleOpenFormUpdateAdmin} // открыть форму редактирования админа
                                handleOpenFormRemoveAdmin={handleOpenFormRemoveAdmin} // открыть форму удаления админа
                                imgUpdate={imgUpdate} // картинка кнопки редактирвоания админа
                                imgRemove={imgRemove} // картинка кнопки удаления админа
                                styleText={textColor} // стиль текста
                            />
                            : <></>
                    }
                    //КНОПКА ДОБАВЛЕНИЯ АДМИНА
                    buttonAddAdmin={
                        < button
                            className={`admin-page__list-comand-button ${backgroundColor}`}
                            onClick={handleOpenFormAddAdmin} >
                            <img className='admin-page__button-img' src={imgAddClients} alt='imgAddClients' />
                        </button >
                    }
                    ishideSearchForm={ishideSearchForm} // пробрасывается стейт, управления отображением инпута поиска
                    isHideFooterForSuperAdmin={isHideFooterForSuperAdmin} // прятать футер когда нужно суперадмину, true - спрятать, не отображать
                    isTrainerAndTraining={isInformationAboutTrainersAndTrainings} // стейт следящий за открытием компонента инф о тренерах и их тренировках
                    changeStateIsTrainerAndTraining={changeStateIsTrainerAndTraining} // поменять стейт следящий за открытием компонента инф о тренерах и их тренировках
                    isSuperadminSelectTrainers={isSwitchControlTrainer} // селектор для определния работы с тренерами или клиентами, true - с тренерами
                    changeTrainers={changeTrainers} // наполнить массив тренеров для отображения
                    isStatisticsSuperAdmin={isStatisticsSuperAdmin} // открыта или нет страница статистики у суперадмина(true - открыта), необходимо, чтобы скрывать некоторые компоненты
                    isSuperAdminSelectAdmins={isSwitchControlAdmin} // селектор для определения работы с админами, true - с тренерами
                    //Пропсы для AdminPage(стандартный)
                    backgroundColor={backgroundColor} // цвет фона
                    textHeaderColor={textHeaderColor} // цвет титульника
                    styleHeaderBoarderColor={styleHeaderBoarderColor}// цвет полоски в хедере
                    button={button} // кнопка ниже хедера
                    section={section} // доп секция выбора меню
                    api={api} // АПИ
                    textButtonSubmit={textButtonSubmit} // текст кнопки сабмита
                    colorBackgroundForm={colorBackgroundForm} // цвет фона формы добавления
                    stylePlaceHolder={stylePlaceHolder} // стиль плейсхолдеров инпутов
                    styleButtonSubmit={styleButtonSubmit} // стиль кнопки сабмита
                    styleButton={styleButton} // стиль кнопок переключения AboutClientPage (абонемент, запись, активные тренировки)
                    styleFooter={styleFooter} // стиль футер
                    previousWeekButtonImg={previousWeekButtonImg} // изображение стрелки перекл дней недели
                    nextMonthButtonImg={nextMonthButtonImg} // изображение стрелки перекл дней недели
                    infoFlex={infoFlex}// давать ли информацию по абонементам Flex
                    infoDark={infoDark} // давать ли информацию по абонементам Dark
                    imgUpdateTraining={imgUpdateTraining} // изображение кнопки редактирования тренировки
                    imgRemoveTraining={imgRemoveTraining} // изображение кнопки удаления тренировки
                    imgWA={imgWA} // изображение кнопки мессенджер
                    imgAddTraining={imgAddTraining} // изображение кнопки добавления тренировки
                    imgTimeTable={imgTimeTable} // изображение кнопки расписания
                    imgGroupeTrain={imgGroupeTrain} // изображение кнопки добавления групповой тренировки
                    imgPersonalTrain={imgPersonalTrain} // изображение кнопки добавления персональной тренировки
                    imgHomePage={imgHomePage} // изображение кнопки главной страницы
                    imgPhoneSearch={imgPhoneSearch} // картинка кнопки поиска по телефону
                    imgNameSurnameSearch={imgNameSurnameSearch} // картинка кнопки поиска по телефону
                    imgList={imgList} // изображение кнопки переклбчения на список в календаре
                    imgAddClients={imgAddClients} // изображение кнопки создания клиента
                    imgCheckOneTimeTraining={imgCheckOneTimeTraining} // изображение кнопки проверки разовых тренировок
                    imgAddOneTimeTraining={imgAddOneTimeTraining} //картинка добавления абонемента для разовой тренировки
                    imgRemoveOneTimeTraining={imgRemoveOneTimeTraining} // картинка удаления разовой тренировки
                    imgHystoryAbonement={imgHystoryAbonement} // картинка истории абонементов
                    imgQRCode={imgQRCode} // изображение кнопки qrcode
                    isActiveTrainer={isActiveTrainer} // для прокидывания с уровня ниже отображения стр управления тренерами
                    isTypeSpace={isTypeSpace} // добавить ли поле выбора зала в форму добавления и редактирования тренировки
                    styleText={textColor}
                />
                : // Если не авторизован
                <SignIn
                    onSubmit={handleSignInSuperAdmin}
                    textMassageInfoTool={isTextMassageInfoTool}
                />
    )
}

export default SuperAdmin;