class Api {
    constructor(data) {
        this.host = data.host;
    }

    // проверка статуса запроса
    _getResponse(res) {
        if (res.ok) {
            return res.json();
        } else {
            return Promise.reject(
                `ошибка: ${res.status} - ${res.statusText}`
            );
        }
    }
    //ДАННЫЕ Клиентов**--------ДАННЫЕ Клиентов**-------------ДАННЫЕ Клиентов**------------ДАННЫЕ Клиентов**---------------ДАННЫЕ Клиентов**-----------------------------------
    //пробросить данные для регистрации через АПИ

    //Авторизация
    loginClient(data) {
        return fetch(`${this.host}/client/signin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ login: data.login, password: data.password, })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }

    //Получить инфо о клиенте(автоматом вкладывает _id в req.user, если авторизован)
    getSelfClient() {
        return fetch(`${this.host}/flex/client`,
            {
                method: 'GET',
                credentials: 'include'
            }
        ).then((res) => this._getResponse(res))
    }

    register(data) {
        return fetch(`${this.host}/flex/control/signup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ name: data.name, surname: data.surname, email: data.email, phoneNumber: data.phoneNumber, password: data.password, })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    };

    //Редактировать клиента
    updateClient(idClient, data) {
        console.log(idClient, data)
        return fetch(`${this.host}/flex/control/client`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                idClient,
                name: data.name,
                surname: data.surname,
                email: data.email,
                phoneNumber: data.phoneNumber,
                password: data.password,
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }

    //пробросить данные из инпутов и отправить на сервер для авторизации пользователя
    login(dataUser) {
        return fetch(`${this.host}/signin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                password: dataUser.password,
                email: dataUser.email,
            })
        }).then((res) => { return res.json() })
            .catch((err) => console.log(err))
    };

    logout() {
        return fetch(`${this.host}/signout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        }).then((res) => { return res.json() })
            .catch((err) => console.log(err))
    }

    //запрос на сервер для авторизации
    getToken(tkn) {
        return fetch(`${this.host}/users/me`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tkn}`,
            },
            credentials: 'include',
        }).then((res) => res.json())
            .catch((err) => console.log(err))
    }

    // запрос данных пользователя (вся инфа: Имя, Телефон, Абонементы, Посещения)
    getUserInfo(idClient) {
        return fetch(`${this.host}/flex/client-info/${idClient}`,
            {
                method: 'GET',
                credentials: 'include'
            }
        ).then((res) => this._getResponse(res))
    }

    // отправка данных пользователя для обновления
    sendUserInfo(data) {
        return fetch(`${this.host}/users/me`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name: data.name, email: data.email }),
        }).then((res) => this._getResponse(res));
    }

    //получить всех клиентов
    getAllClients() {
        return fetch(`${this.host}/flex/get-clients`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': `Bearer ${tkn}`,
            },
            credentials: 'include',
        }).then((res) => res.json())
            .catch((err) => console.log(err))
    }

    //Получить данные клиента по поиску из инпутов
    getClientForName(name, surname) {
        let url = `${this.host}/flex/control/get-client?`;
        if (surname) {
            url += `surname=${surname}&`;
        }
        if (name) {
            url += `name=${name}&`;
        }
        return fetch(url.slice(0, -1), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }

    //Получить данные клиента по поиску из инпутов
    getClientForNumber(number) {
        let url = `${this.host}/flex/control/get-client-phonenumber/:number?`;
        if (number) {
            url += `number=${number}&`;
        }
        return fetch(url.slice(0, -1), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }

    addClientComment(idAdmin, idClient, comment) {
        return fetch(`${this.host}/flex/client-comment/${idClient}`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ comment, idAdmin }),
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }



    //АБОНЕМЕНТЫ**---------АБОНЕМЕНТЫ**---------АБОНЕМЕНТЫ**---------АБОНЕМЕНТЫ**---------АБОНЕМЕНТЫ**---------АБОНЕМЕНТЫ**---------АБОНЕМЕНТЫ**---------
    // запрос всех абонементов с сервера 
    getAllAbonements() {
        return fetch(`${this.host}/flex/control/abonements`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => this._getResponse(res));
    }

    // получить абонементы по клиенту
    getClientAbonement(data) {
        return fetch(`${this.host}/flex/control/abonements/${data}`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => this._getResponse(res));
    }

    //получить абонементы по месяцу и году
    getAbonementForMonthAndYear(month, year) {
        return fetch(`${this.host}/flex/superior/abonements-m-y/${month}/${year}`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => this._getResponse(res));
    }

    // получить архивные абонементы по месяцу и году
    getHystoryAbonementForMonthAndYear(month, year) {
        return fetch(`${this.host}/flex/superior/hystoryabonements-m-y/${month}/${year}`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => this._getResponse(res));
    }

    // Получить архив абонементов по клиенту
    getHystoryAbonement(idClient) {
        return fetch(`${this.host}/flex/control/hystoryabonement/${idClient}`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => this._getResponse(res));
    }

    // создать абонемент 
    createAbonement(data, idClient) {
        return fetch(`${this.host}/flex/control/abonements`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                client: idClient, // id клиента
                dateOn: data.dateOn, // дата начала
                dateTo: data.dateTo, // дата до
                quantityVisitsOn: data.quantityVisitsOn, // количество тренировок в абонементе
                quantityVisits: data.quantityVisits, // остаток тренировок в абонементе
                type: data.type, // тип абонемента
                cost: data.cost, // стоимость
            }),
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    //скорректировать абонемент
    updateAbonement(data, id) {
        return fetch(`${this.host}/flex/abonement/control/${id}`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                dateOn: data.dateOn, // дата начала
                dateTo: data.dateTo, // дата до
                quantityVisits: data.quantityVisits, // количество тренировок в абонементе
                quantityVisitsOn: data.quantityVisitsOn,
                type: data.type, // тип абонемента
                cost: data.cost, // стоимость абонемента
            }),
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    //Отправить заявку на заморозку абонемента
    sendFreezeAbonementfetch(formData) {
        return fetch(`${this.host}/flex/abonement-freeze`, {
            method: "POST",
            credentials: 'include',
            body: formData,
            headers: {
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
            },
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    //СТОИМОСТЬ АБОНЕМЕНТОВ
    //получить стоимость всех абонементов
    getAbonementCosts() {
        return fetch(`${this.host}/flex/control/abonementcosts`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => this._getResponse(res));
    }

    //Создать стоимость абонементов
    createCost(type, cost) {
        return fetch(`${this.host}/flex/superior/abonementscosts`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                cost,
                type,
            }),
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    //Редактировать стоимость абонементов
    updateCost(type, cost) {
        return fetch(`${this.host}/flex/superior/abonementscosts`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                type: type, // тип абонемента
                cost: cost, // стоимость абонемента
            }),
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }


    //ТРЕНИРОВКИ-------------ТРЕНИРОВКИ-------------ТРЕНИРОВКИ-------------ТРЕНИРОВКИ-------------ТРЕНИРОВКИ-------------ТРЕНИРОВКИ-------------ТРЕНИРОВКИ-------------ТРЕНИРОВКИ-------------ТРЕНИРОВКИ-------------ТРЕНИРОВКИ-------------ТРЕНИРОВКИ-------------
    // получить все тренировки
    getAllTrains() {
        return fetch(`${this.host}/flex/trains`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => this._getResponse(res));
    }

    // получить конкретную тренировку по id
    getTrainById(id) {
        return fetch(`${this.host}/flex/control/train/${id}`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => this._getResponse(res));
    }

    //получить тренировки для отрисовки календаря
    getTrainsForCalendar() {
        return fetch(`${this.host}/flex/trains-calendar`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => this._getResponse(res));
    }

    // получить тренировки по датам
    getTrainsOfDate(date) {
        return fetch(`${this.host}/flex/trains-date/${date}`, {
            method: 'GET',
            credentials: 'include',
        }).then((res) => this._getResponse(res));
    }

    // получить тренировки, на которые записан клиент
    getTrainsOfClientsVisit(id) {
        return fetch(`${this.host}/flex/trains-client/${id}`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => this._getResponse(res));
    }

    // получить тренировки, на которые записан клиент на конкретную дату
    getTrainsOfClientsVisitDate(clientId, dateTrain) {
        return fetch(`${this.host}/flex/trains-client/${clientId}/${dateTrain}`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => { this._getResponse(res) });
    }
    // получить все тренировки проведенные тренером в течение месяца
    getAllTrainOfTrainerForMonth(idTrainer, month, year) {
        return fetch(`${this.host}/flex/trainer-month/${idTrainer}/${month}/${year}`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => this._getResponse(res));
    }

    // добавить тренировку (групповые, аренда, самотрен)
    createTrain(data) {
        return fetch(`${this.host}/flex/add-train`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: data.nameTrain, // название тренировки
                trainer: data.trainer, // id из БД тренера
                dateOn: data.dateOn, // дата тренировки ( уникальная
                dateTo: data.dateTo,
                capacity: data.capacity,
                typeOfTraining: data.typeTrain,
                repeat: data.repeatValue,
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    // создать персональную тренировку Flex ( внутри идет сразу же запись на эту тренировку клиента, отдельно как у групповых записывать не нужно по функционалу)
    createPersonalTraining(data, idClient) {
        return fetch(`${this.host}/flex/control/train/createpersonal`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idClient: idClient, // айди клиента
                name: data.nameTrain, // название тренировки
                trainer: data.trainer, // id из БД тренера
                dateOn: data.dateOn, // дата тренировки ( уникальная)
                dateTo: data.dateTo,
                capacity: data.capacity,
                typeOfTraining: data.typeTrain,
                repeat: data.repeatValue, // не повторять персональные
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });

    }

     // создать сплит тренировку Flex ( внутри идет сразу же запись на эту тренировку клиента, отдельно как у групповых записывать не нужно по функционалу)
     createSplitTraining(data, idClients) {
        return fetch(`${this.host}/flex/control/train/createsplit`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idClients: idClients, // айди клиента
                name: data.nameTrain, // название тренировки
                trainer: data.trainer, // id из БД тренера
                dateOn: data.dateOn, // дата тренировки ( уникальная)
                dateTo: data.dateTo,
                capacity: data.capacity,
                typeOfTraining: data.typeTrain,
                repeat: data.repeatValue, // не повторять персональные
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });

    }

    //удалить тренировку
    removeTrain(idTrain) {
        return fetch(`${this.host}/flex/remove-train/${idTrain}`, {
            method: 'DELETE',
            credentials: 'include'
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }


    //удалить тренировку
    removeManyTrain(idTrain) {
        return fetch(`${this.host}/flex/remove-many-train/${idTrain}`, {
            method: 'DELETE',
            credentials: 'include'
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    // редактерировать тренировку
    updateTrain(idTrain, name, trainer, capacity) {
        return fetch(`${this.host}/flex/update-train/${idTrain}`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name,
                capacity,
                trainer,
            }),
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }

    // добавить клиента на тренировку(только по действующему абонементу)
    addVisitOnTrain(idTrain, idClient, typeOfTraining) {
        return fetch(`${this.host}/flex/add-visit`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idClient: idClient, 
                idTrain: idTrain, 
                typeOfTraining: typeOfTraining, // тип тренировки
            }),
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }

    // добавить клиента на разовую групповую тренировку предварительно создав её(только админ, клиент сам не может, перс в отдельном методе ниже)
    addOneTimeGroupeVisit(
        idClient, // id клиента
        idTrain, // id тренировки
        typeOfTraining, // тип тренировки
    ) {
        return fetch(`${this.host}/flex/control/train/onetimegroupevisit`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idClient: idClient,
                idTrain: idTrain,
                typeOfTraining: typeOfTraining,
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });

    }

    // отменить запись (клиент сам отменяет)
    removeVisitOnTrain(idTrain, idClient) {
        return fetch(`${this.host}/flex/remove-visit`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idClient: idClient,
                idTrain: idTrain, // id из БД тренировки
            }),
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }

    // отмена визита на тренировку(Доступ админа, тк можно отменить и после начала тренировки)
    removeVisitClientByAdmin(idTrain, idClient) {
        return fetch(`${this.host}/flex/control/removevisit`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idClient: idClient,
                idTrain: idTrain, // id из БД тренировки
            }),
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }

    //Разовые записи на тренировки
    // получить все разовые записи
    getListOneTimeTrains() {
        return fetch(`${this.host}/flex/listonetime`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => this._getResponse(res));
    }

    //Создать разовую запись на тренировку 
    createListOneTimeTrain(idTrain, idClient) {
        return fetch(`${this.host}/flex/listonetime`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idClient: idClient,
                idTrain: idTrain,
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    //отменить разовую запись на тренировку
    removeListOneTimetraining(idList, idClient, idTrain, typeOfTraining) {
        return fetch(`${this.host}/flex/listonetimeremove`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idList: idList,
                idClient: idClient,
                idTrain: idTrain,
                typeOfTraining: typeOfTraining,
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    // Создать абонемент на разовую тренировку после потверждения присутствия на тренировке
    createAbonementForOneTimeTraining(idTrain, trainDateOn, idClient, abonementType, abonementCost
        , idList) {
        return fetch(`${this.host}/flex/onetimeaddabonement`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                trainDateOn: trainDateOn,
                idClient: idClient,
                idTrain: idTrain,
                abonementType,
                idList,
                costAbonement: abonementCost
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    //ТРЕНЕРЫ----------ТРЕНЕРЫ----------ТРЕНЕРЫ----------ТРЕНЕРЫ----------ТРЕНЕРЫ----------ТРЕНЕРЫ----------ТРЕНЕРЫ----------ТРЕНЕРЫ----------ТРЕНЕРЫ----------ТРЕНЕРЫ----------
    getSelfTrainer() {
        return fetch(`${this.host}/flex/trainer`,
            {
                method: 'GET',
                credentials: 'include'
            }
        ).then((res) => this._getResponse(res))
    }

    //получить всех тренеров
    getAllTrainers() {
        return fetch(`${this.host}/flex/trainers`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => this._getResponse(res));
    }

    //получить тренера по id
    getTrainerById(id) {
        return fetch(`${this.host}/flex/trainer/${id}`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => this._getResponse(res));
    }

    //Получить данные тренера по поиску из инпутов
    getTrainerForName(name, surname) {
        let url = `${this.host}/flex/get-trainer?`;
        if (surname) {
            url += `surname=${surname}&`;
        }
        if (name) {
            url += `name=${name}&`;
        }
        return fetch(url.slice(0, -1), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }

    //Получить данные тренера по поиску из инпутов
    getTrainerForNumber(number) {
        let url = `${this.host}/flex/get-trainer-phonenumber?`;
        if (number) {
            url += `number=${number}&`;
        }
        return fetch(url.slice(0, -1), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }

    //добавить тренера
    createTrainer(data) {
        return fetch(`${this.host}/flex/superior/trainer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                name: data.name,
                surname: data.surname,
                email: data.email,
                phoneNumber: data.phoneNumber,
                password: data.password,
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }

    //Редактировать тренера
    updateTrainer(idTrainer, data) {
        return fetch(`${this.host}/flex/superior/trainer`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                idTrainer,
                name: data.name,
                surname: data.surname,
                email: data.email,
                phoneNumber: data.phoneNumber,
                password: data.password,
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }

    //Удалить тренера
    removeTrainer(idTrainer) {
        return fetch(`${this.host}/flex/superior/trainer/${idTrainer}`, {
            method: 'DELETE',
            credentials: 'include',
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    //Админы

    getSelfAdmin() {
        return fetch(`${this.host}/flex/control`,
            {
                method: 'GET',
                credentials: 'include'
            }
        ).then((res) => this._getResponse(res))
    }

    //Получить данные админа по поиску из инпутов
    getAdmins() {
        let url = `${this.host}/flex/superior/get-admins`;

        return fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }

    //добавить админа
    createAdmin(data) {
        return fetch(`${this.host}/flex/superior/admin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                name: data.name,
                surname: data.surname,
                phoneNumber: data.phoneNumber,
                password: data.password,
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }

    //Редактировать админа
    updateAdmin(idAdmin, data) {
        return fetch(`${this.host}/flex/superior/admin`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                idAdmin,
                name: data.name,
                surname: data.surname,
                email: data.email,
                phoneNumber: data.phoneNumber,
                password: data.password,
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }

    //Удалить админа
    removeAdmin(idAdmin) {
        return fetch(`${this.host}/flex/superior/adminr/${idAdmin}`, {
            method: 'DELETE',
            credentials: 'include',
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    //СуперАдмин
    getSelfSuperAdmin() {
        return fetch(`${this.host}/flex/superior`,
            {
                method: 'GET',
                credentials: 'include'
            }
        ).then((res) => this._getResponse(res))
    }


    //ОПЛАТЫ ЗА ПОСЕЩЕНИЯ--------------ОПЛАТЫ ЗА ПОСЕЩЕНИЯ--------------ОПЛАТЫ ЗА ПОСЕЩЕНИЯ--------------ОПЛАТЫ ЗА ПОСЕЩЕНИЯ--------------ОПЛАТЫ ЗА ПОСЕЩЕНИЯ--------------
    //Получить оплаты за посещения
    getPaymentToTheTrainer() {
        return fetch(`${this.host}/flex/superior/trainerpayment`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => this._getResponse(res));
    }

    // создать оплату за посещение
    createPaymentToTheTrainer(type, payment) {
        return fetch(`${this.host}/flex/superior/trainerpayment`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                payment,
                type,
            }),
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    // редактировать оплату за посещение
    updatePaymentToTheTrainer(type, payment) {
        return fetch(`${this.host}/flex/superior/abonementscosts`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                type: type, // тип абонемента
                payment: payment, // стоимость абонемента
            }),
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

}

const apiFlex = new Api({
    host: 'https://api.darkflex.ru',
    //host: 'https://www.api.darkflex.ru',
    //host: 'http://localhost:3000',
});

export default apiFlex;