import SuperAdmin from "../SuperAdmin/SuperAdmin"
import apiDark from '../../utils/ApiDark';
import apiAuth from '../../utils/ApiAuth';

import nextMonthButtonImg from './../../images/next-week.svg'
import previousWeekButtonImg from './../../images/previous-week.svg'
import imgUpdate from './../../images/edit-pencil-line-01-svgrepo-com.svg'
import imgRemove from './../../images/delete-1-svgrepo-com.svg'
import imgWA from './../../images/message-three-points-1560-svgrepo-com.svg';
import imgAddTraining from './../../images/add-square-s.svg';
import imgTimeTable from './../../images/calendar-1-svgrepo-com.svg';
import imgGroupeTrain from './../../images/group-svgrepo-com.svg';
import imgPersonalTrain from './../../images/personal.svg';
import imgHomePage from './../../images/home-1-svgrepo-com.svg';
import imgList from './../../images/list-center-svgrepo-com.svg';
import imgAddClients from './../../images/add-profile-svgrepo-com.svg'
import imgCheckOneTimeTraining from './../../images/question-answer-help-svgrepo-com.svg'
import imgAddOneTimeTraining from './../../images/check-svgrepo-com.svg' //картинка добавления абонемента для разовой тренировки
import imgRemoveOneTimeTraining from './../../images/remove-svgrepo-com.svg' // картинка удаления разовой тренировки
import imgHystoryAbonement from './../../images/inbox-empty-round-1553-svgrepo-com.svg'; // картинка истории абонементов
import imgStatisticsButton from './../../images/archive-2-svgrepo-com.svg' // картинка кнопки
import imgStatisticsAbonements from './../../images/note-text-svgrepo-com.svg' // кнопка статистики абонементов
import imgChangeStatisticsAbonements from './../../images/change-management-backup-svgrepo-com.svg' // кнопка смены статистики по абонементам с общ инф на список ии обратно
import imgSettingsAbonements from './../../images/setting-3-svgrepo-com.svg'// кнопка настройки абонементов
import imgSettingsPaymentsToTrainer from './../../images/payment-card-svgrepo-com.svg' // кнопка настройки оплат за посещения тренерам
import imgPhoneSearch from './../../images/Dark/mobile-svgrepo-com.svg' // картинка поиска по телефону
import imgNameSurnameSearch from './../../images/Dark/search-svgrepo-com.svg'// картинка поиска по имени и фамилии
import imgQRCode from './../../images/qrcode-svgrepo-com.svg'// картинка qrcode


function SuperAdminDark() {

    return <SuperAdmin
        backgroundColor={'dark'} // цвет фона
        backgroundColorButton={'about-client-page__button_flex'} // цвет фона кнопок
        api={apiDark} // Апи
        apiAuth={apiAuth} // Апи Авторизации
        styleButton={'flex__buttons'} // стиль кнопок
        textButtonSubmit={'Отправить'}// текст кнопки сабмита(добавления клиента)
        colorBackgroundForm={'dark__form'} // цвет фона формы сабмита нового клиента
        stylePlaceHolder={'clients-table__form__input_flex::placeholder'} // стиль плейсхолдера у инпутов
        styleButtonSubmit={'formadd__button_flex'} // стиль кнопки сабмита
        textHeaderColor={'flex__title-color-text'} // цвет текста Титульника
        textColor={'dark__color-text'} // цв текста
        //Футер
        styleFooter={'dark__footer'} // стиль футера
        styleHeaderBoarderColor={'admin-page__header-boarder'} // цвет полоски в хедере
        previousWeekButtonImg={previousWeekButtonImg} // изображение стрелки перекл дней недели
        nextMonthButtonImg={nextMonthButtonImg} // изображение стрелки перекл дней недели
        infoFlex={false}// давать ли информацию по абонементам Flex
        infoDark={true} // давать ли информацию по абонементам Dark
        isSuperAdmin={true} //Возможность добавлять группы только у суперадмина
        imgUpdateTraining={imgUpdate} // изображение кнопки редактирования тренировки
        imgRemoveTraining={imgRemove} // изображение кнопки удаления тренировки
        imgWA={imgWA} // изображение кнопки мессенджер
        imgAddTraining={imgAddTraining} // изображение кнопки добавления тренировки
        imgTimeTable={imgTimeTable} // изображение кнопки расписания
        imgGroupeTrain={imgGroupeTrain} // изображение кнопки добавления групповой тренировки
        imgPersonalTrain={imgPersonalTrain} // изображение кнопки добавления персональной тренировки
        imgHomePage={imgHomePage} // изображение кнопки главной страницы
        imgPhoneSearch={imgPhoneSearch} // картинка кнопки поиска по телефону
        imgNameSurnameSearch={imgNameSurnameSearch} // картинка кнопки поиска по телефону
        imgList={imgList} // изображение кнопки переклбчения на список в календаре
        imgAddClients={imgAddClients} // изображение кнопки создания клиента
        imgCheckOneTimeTraining={imgCheckOneTimeTraining} // изображение кнопки проверки разовых тренировок
        imgAddOneTimeTraining={imgAddOneTimeTraining} //картинка добавления абонемента для разовой тренировки
        imgRemoveOneTimeTraining={imgRemoveOneTimeTraining} // картинка удаления разовой тренировки
        imgHystoryAbonement={imgHystoryAbonement} // картинка истории абонементов
        imgStatisticsButton={imgStatisticsButton} // кнопка статистики
        imgStatisticsAbonements={imgStatisticsAbonements} // кнопка статистики абонементов
        imgStatisticsVisits={imgGroupeTrain} //  кнопка статистики посещений
        imgChangeStatisticsAbonements={imgChangeStatisticsAbonements} // кнопка смены статистики по абонементам с общ инф на список ии обратно
        imgSettingsAbonements={imgSettingsAbonements} // кнопка настройки абонементов
        imgSettingsPaymentsToTrainer={imgSettingsPaymentsToTrainer} // кнопка настройки оплат тренерам за посещения
        imgUpdate={imgUpdate} // изображение кнопки редактирования 
        imgRemove={imgRemove} // изображение кнопки удаления
        imgQRCode ={imgQRCode} // изображение qrcode
        isTypeSpace={true}
    />
}

export default SuperAdminDark;