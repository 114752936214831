class ApiAuth {
    constructor(data) {
        this.host = data.host;
    }

    // проверка статуса запроса
    _getResponse(res) {
        if (res.ok) {
            return res.json();
        } else {
            return Promise.reject(
                `ошибка: ${res.status} - ${res.statusText}`
            );
        }
    }

    //Авторизация тренера
    loginTrainer(data) {
        return fetch(`${this.host}/trainersignin/signin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ login: data.login, password: data.password, })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }

    //Авторизация админа
    loginAdmin(data) {
        return fetch(`${this.host}/controlsignin/signin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ login: data.login, password: data.password, })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }

    //Авторизация Суперадмина
    loginSuperAdmin(data) {
        return fetch(`${this.host}/superiorsignin/signin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ login: data.login, password: data.password, })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }
}

const apiAuth = new ApiAuth({
    host: 'https://api.darkflex.ru',
    //host: 'https://www.api.darkflex.ru',
    //host: 'http://localhost:3000',
});

export default apiAuth;