//Информация о клиенте
// AdminPage -> this

import { useEffect, useState } from "react";

import FormAddAbonement from "../FormAddAbonement/FormAddAbonement"; // форма работы с абонементом
import FormAddTrain from "../FormAddTrain/FormAddTrain"; // форма добавления персональной тренировки
import TimeTable from "../TimeTable/TimeTable"; // раписание активных тренировок для записи на тренировку
import ClientInfo from "../ClientInfo/ClientInfo"; // инфо о клиенте ( абонементы и данные)
import CommentsTable from "../CommentsTable/CommentsTable"; // таблица комментриев
import { useLocation } from "react-router-dom";
import InfoTooltip from "../InfoTooltip/InfoTooltip";
import HystoryAbonementClient from "../HystoryAbonementClient/HystoryAbonementClient"; // история абонементов у клиента

function AboutClientPage({
    backgroundColor, // цвет фона
    styleButton, // стиль кнопок
    colorButtonSubmit, // стиль кнопки сабмита
    api, // АПИ
    isIdClient, // передать айди клиента в AboutClientPage для получения инф и отрисовки
    infoFlex, // давать ли информацию по абонементам Flex
    infoDark, // давать ли информацию по абонементам Dark
    isTypeSpace, // выбор зала, true - добавить выбор
    isClickAddClientOnGroupe, // стейт запись на групповую тренировку
    handleIsClickAddClientOnGroupe, // функция возврата isClickAddClientOnGroupe в false
    isClickAddClientOnPersonal,// стейт запись на персональную тренировку
    trainers, // массив тренеров для создания персональной тренировки и выбора тренера
    handleIsClickAddClientOnPersonal, // функция возврата isClickAddClientOnPersonal в false
    isClientHystoryAbonement, // управлять открытием истории абонементов у клиента
    handleisClientHystoryAbonement, // функция возврата isClientHystoryAbonement в false
    hystoryAbonement, // архив абонементов по клиенту
    handleIsUpdateClientButton, // слушать показывать ли кнопку редактирования клиента в футере
    timetableWeekButtonStyle, // стиль кнопок перекл недели
    styleText, // стиль текста
    colorBackgroundForm, // цвет фона форм
}) {

    //Данные
    const [listOfVisits, setListOfVisits] = useState([]); // список посещений тренировок внутри выбранного абонемента
    const [trains, setTrains] = useState([]); // массив тренировок(общий)
    const [groupeTrains, setGroupeTrains] = useState([]); // массив только групповых тренировок
    const [activeTrains, setActiveTrains] = useState([]); // массив тренировок для отображения в активных записях клиента
    const [nameClient, setNameClient] = useState('') // имя клиента
    const [surnameClient, setSurnameClient] = useState('') // фамилия клиента
    const [phoneNumberClient, setPhoneNumberClient] = useState('') // сотовый номер клиента
    const [abonementClientFlexGroupe, setabonementClientFlexGroupe] = useState(undefined) // объект абонемента клиента
    const [abonementClientFlexPersonal, setabonementClientFlexPersonal] = useState(undefined) // объект абонемента клиента
    const [abonementClientDarkGroupe, setabonementClientDarkGroupe] = useState(undefined) // объект абонемента клиента
    const [abonementClientDarkPersonal, setabonementClientDarkPersonal] = useState(undefined) // объект абонемента клиента
    const [abonementClientGroupe, setAbonementClientGroupe] = useState(undefined) // объект абонемента клиента для передачу в форму в зависимости от адреса
    const [abonementClientPersonal, setAbonementClientPersonal] = useState(undefined) // объект абонемента клиента для передачу в форму в зависимости от адреса
    const [abonementClient, setAbonementClient] = useState(undefined) // проставлять актуальный абонемент в зависимости от свитчера
    const [commentsClient, setCommentsClient] = useState([]) // массив комментариев по клиенту
    const [abonementCosts, setAbonementCosts] = useState([]) // массив данных по стоимости абонемента
    const [optionAbonement, setOptionAbonement] = useState(''); // групповой или персональный абонемент для названия формы редактирования
    //инфотул
    const [isInfoToolOpen, setIsInfoToolOpen] = useState(false); // стейт для открытия информационного окна
    const [isTextMassageInfoTool, setTextMassageInfoTool] = useState(''); // текс в инфотул
    //управление
    const [isChange, setIsChange] = useState(false) // отслеживать изменения абонемента для мнгновенной отрисовки без обновления страницы
    const [loaderTimeTable, setLoaderTimeTable] = useState(false); // управлять лоадером, пока приходят даннные с сервера для расписания
    const [activeTrainingSessions, setActiveTrainingSessions] = useState(true); // отобразить активные записи на тренировки клиента
    const [showFormAbonement, setShowFormAbonement] = useState(false); // отображать информацию об абонементе
    const [activeTrainsTimeTable, setActiveTrainsTimeTable] = useState(false); //
    const [isDesiredAddress, setIsDesiredAddress] = useState('Flex'); // управление страницей
    const [isCheckGroup, setIsCheckGroup] = useState(true); // управление отображение групповых тренировок/абонементов
    const [isCheckPersonal, setIsCheckPersonal] = useState(false); // управление отображение персональных тренировок/абонементов
    const [isAddPersonal, setAddPersonal] = useState(false); // управлять открытием формы добавления персональной тренировки
    const [isComments, setIsComments] = useState(false); // управлять отображением комментариев о клиенте

    const location = useLocation(); // получать адрес сайтаhandleChangeAbonement

    //Инфотул
    //Свернуть инфотул
    function closeInfotool() {
        setIsInfoToolOpen(false);
    }

    //Отследить изменения для отрисовки абонемента в дочерних компонентах
    function handleChangeAbonement() {
        setIsChange(true) // мгновенно отрисовать изменения в абонементе на странице
    }

    // ТРЕНИРОВКИ
    function handleSetActiveTrains(date) { // изменять массив активных записей, тренировок(список)
        setActiveTrains(date);
    }

    function handleSetTrains(date) { // изменять массив тренировок(список) только групповые
        //  console.log(1)
        setTrains(date);
    }

    //управление отображением информации на странице
    function showActiveTrainingSessions() { // показать активные записи
        setActiveTrainingSessions(true)
        setShowFormAbonement(false);
        setActiveTrainsTimeTable(false);
        handleIsClickAddClientOnGroupe(false);
        setIsComments(false);
        handleisClientHystoryAbonement(false);
    }

    function showabonementClient(abonement, options) { // открыть форму для работы с абонементом, пробрасывается в ClientInfo, тк кнопка - инфа об абонементе
        setAbonementClient(abonement)
        setOptionAbonement(options);
        if (options === 'групповой') {
            setIsCheckGroup(true);
        } else if (options === 'персональный') {
            setIsCheckGroup(false);
        }
        setActiveTrainsTimeTable(false);
        handleIsClickAddClientOnGroupe(false);
        setIsComments(false);
        setShowFormAbonement(true);
    }

    //СОЗДАТЬ НОВУЮ ПЕРСОНАЛЬНУЮ ТРЕНИРОВКУ
    function handleAddPersonalTrainSubmit(data) {
        api.createPersonalTraining(data, isIdClient)
            .then((data) => {
                handleIsClickAddClientOnPersonal(false); // свернуть форму добавления перс трен
                setIsInfoToolOpen(true);
                setIsChange(true);
                setTextMassageInfoTool('Персональная тренировка создана, клиент записан')
            })
            .catch(err => {
                console.log(err);
                handleIsClickAddClientOnPersonal(false);
                setIsInfoToolOpen(true);
                setTextMassageInfoTool(`Произошла ошибка ${err.message}`);
            })
    }

    // КОММЕНТАРИИ
    function openComments() { // открыть комментарии
        setIsComments(true);
        setActiveTrainingSessions(false)
        setShowFormAbonement(false);
        setActiveTrainsTimeTable(false);
        handleIsClickAddClientOnGroupe(false);
        handleisClientHystoryAbonement(false);
    }

    function addCommentClient(newComments) { // отрисовать новый комментарий на странице без перезагрузки(поменять стейт)
        setCommentsClient(newComments);
    }

    //РАБОТА С АБОНЕМЕНТОМ

    //Обновить абонемент
    function handleSubmitFormUpdateAbonement(data) { // Сабмит, пробрасывается в FormAddAbonement, data из FormAddAbonement
        let abonementClientId;
        abonementClientId = isCheckGroup ? abonementClientGroupe._id : abonementClientPersonal._id // выбор групповой или персональный
        api.updateAbonement(data, abonementClientId)
            .then((updateAbonement) => {
                setIsInfoToolOpen(true);
                setTextMassageInfoTool('абонемент успешно отредактирвован')
                setShowFormAbonement(false);
                setIsChange(true) // мгновенно отрисовать изменения в абонементе на странице
            }).catch((err) => {
                console.log(err);
                setIsInfoToolOpen(true);
                setTextMassageInfoTool(`произошла ошибка ${err.message}`)// управлять текстом инфотула
            })
    }

    //Добавить(создать) абонемент
    function handleSubmitFormAddAbonement(data) {
        api.createAbonement(data, isIdClient)
            .then((newAbonement) => {
                setIsChange(true);
                setIsInfoToolOpen(true);
                setTextMassageInfoTool('абонемент успешно создан')
                setIsChange(true) // мгновенно отрисовать изменения в абонементе на странице
                setShowFormAbonement(false);
            }).catch((err) => {
                console.log(err.message);
                setIsInfoToolOpen(true);
                setTextMassageInfoTool(`произошла ошибка ${err.message}`)
            })
    }

    function closeSubmitFormAddAbonement() {
        setShowFormAbonement(false);
    }

    //Отображать только визиты до текущей даты
    const filterVisitsByDate = (visits, currentDate) => {
        const timeZone = 'Asia/Yekaterinburg';

        // Получаем текущую дату и время в часовом поясе Екатеринбурга с локализацией на русский язык
        const currentDateTime = new Date().toLocaleString('ru-RU', { timeZone });

        return visits.filter((visit) => {
            // Преобразуем дату и время визита в часовой пояс Екатеринбурга с локализацией на русский язык
            const visitDateTime = new Date(visit.dateOn).toLocaleString('ru-RU', {
                timeZone,
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: false,
            });

            return visitDateTime <= currentDateTime;
        });
    };

    useEffect(() => {
        let visits = [];

        if (
            abonementClientFlexGroupe !== undefined &&
            abonementClientFlexGroupe !== null &&
            abonementClientFlexGroupe.listOfVisits !== undefined
        ) {
            visits = filterVisitsByDate( // наполнить массив визитами до текущей даты
                abonementClientFlexGroupe.listOfVisits,
                new Date()
            );
        }
        if (
            abonementClientFlexPersonal !== undefined &&
            abonementClientFlexPersonal !== null &&
            abonementClientFlexPersonal.listOfVisits !== undefined
        ) {
            visits = [
                ...visits,
                ...filterVisitsByDate(
                    abonementClientFlexPersonal.listOfVisits,
                    new Date()
                ),
            ];
        }

        setListOfVisits(visits);
    }, [abonementClientFlexGroupe, abonementClientFlexPersonal, isChange]);


    // получить информацию о клиенте для отрисовки
    useEffect(() => {
        api.getUserInfo(isIdClient)
            .then((userInfo) => {
                let clientInfo = userInfo.user;
                setNameClient(clientInfo.name)
                setSurnameClient(clientInfo.surname)
                setPhoneNumberClient(clientInfo.phoneNumber)
                setabonementClientFlexGroupe(clientInfo.abonementFlexGroupe)
                setabonementClientFlexPersonal(clientInfo.abonementFlexPersonal)
                setabonementClientDarkGroupe(clientInfo.abonementDarkGroupe)
                setabonementClientDarkPersonal(clientInfo.abonementDarkPersonal)
                setCommentsClient(clientInfo.comments)
            }).catch((err) => { console.log(err) });
    }, [isChange])

    // получить список тренировок клиента, на которые он записан
    useEffect(() => {
        setLoaderTimeTable(true);
        let date = new Date();
        api.getTrainsOfClientsVisitDate(isIdClient, date)
            .then((trains) => {
                setActiveTrains(trains);
                setLoaderTimeTable(false)
            }).catch((err) => { console.log(err) });
    }, [isChange]);

    // отследить адрес для передачи в стейт и работы с абонементом Flex или Dark
    useEffect(() => {
        api.getUserInfo(isIdClient)
            .then((userInfo) => {
                let clientInfo = userInfo.user;
                setNameClient(clientInfo.name)
                setSurnameClient(clientInfo.surname)
                setPhoneNumberClient(clientInfo.phoneNumber)
                if (infoFlex) {
                    setIsDesiredAddress("Flex");
                    setAbonementClientGroupe(clientInfo.abonementFlexGroupe)
                    setAbonementClientPersonal(clientInfo.abonementFlexPersonal)
                }
                if (infoDark) {
                    setIsDesiredAddress("Dark");
                    setAbonementClientGroupe(clientInfo.abonementDarkGroupe)
                    setAbonementClientPersonal(clientInfo.abonementDarkPersonal)
                }
            }).catch((err) => { console.log(err) });
    }, [infoFlex, infoDark]);

    // проставить абонемент в форму в зависимости от клика
    useEffect(() => {
        isCheckGroup ? setAbonementClient(abonementClientGroupe) : setAbonementClient(abonementClientPersonal);
    }, [isCheckGroup, isCheckPersonal, abonementClientGroupe, abonementClientPersonal]);

    //управлять кнопками и отображением компонентов в зависимости от активной кнопки
    useEffect(() => {
        if (isClientHystoryAbonement === true) { // если активна история абонементов
            handleIsClickAddClientOnGroupe(false);
            setActiveTrainsTimeTable(false); // свернуть рапсписание
            setActiveTrainingSessions(false); // свернуть активные записи
            setIsComments(false);
        }
        if (isClickAddClientOnGroupe === true) { // если активна групповая запись
            setActiveTrainsTimeTable(true);
            setActiveTrainingSessions(true);
            setShowFormAbonement(false);
            setIsComments(false);
            handleisClientHystoryAbonement(false); // свернуть историю абонементов
        }
    }, [isClickAddClientOnGroupe, isClickAddClientOnPersonal, isClientHystoryAbonement])

    useEffect(() => {

    }, [isClientHystoryAbonement])

    useEffect(() => { // автоматически закрыть инфотул
        if (isInfoToolOpen) {
            setTimeout(() => { setIsInfoToolOpen(false) }, 3000)
        }
    }, [isInfoToolOpen])

    //Слушать измения и сбрасывать стейт контроля изменений
    useEffect(() => {
        if (isChange) {
            setTimeout(() => { setIsChange(false) }, 1000)
        }
    }, [isChange]);

    // получить текущую дату и загрузить активные записи на нее для первоначального отображения или тренировки клиента
    useEffect(() => {
        if (activeTrainingSessions) {
            api.getTrainsOfClientsVisit(isIdClient) // получить все тренировки, на которые записан клиент
                .then((trains) => {
                    let filteredTrains = trains.trains.filter((train) => new Date(train.dateOn) >= new Date());
                    setActiveTrains(filteredTrains);
                }).catch((err) => {
                    console.log(err);
                    // getUploaderTimeTeble(false)// спрятать лоадер
                });
        }
    }, [activeTrainingSessions, isIdClient, isChange]) // isChange - мгновенная отрисовка при изменения на странице

    // Получить стоимость абонементов
    useEffect(() => {
        api.getAbonementCosts()
            .then((costs) => { setAbonementCosts(costs.abonementCosts); })
            .catch((err) => { console.log(err); })
    }, [])

    //Наполнить массив всех тренировок
    useEffect(() => {
        const now = new Date();
        api.getTrainsOfDate(now)
            .then((array) => {
                setTrains(array.trains);
            })
            .catch((error) => { console.error(error) })
    }, [activeTrainsTimeTable, activeTrainingSessions])

    // наполнить массив групповых тренировок из общего списка тренировок
    useEffect(() => {
        const filteredDate = trains.filter(obj => obj.typeOfTraining === "групповая");
        setGroupeTrains(filteredDate);
    }, [trains, isChange])

    //Передавать стейт для отрисовки кнопки редактирования клиента
    useEffect(() => {
        if (isComments && handleIsUpdateClientButton !== undefined) {
            handleIsUpdateClientButton(true);
        } else {
            handleIsUpdateClientButton(false);
        }
    }, [isComments])

    return (
        <div className="about-client-page">
            {/* isInfoToolOpen */}
            {isInfoToolOpen ?
                <InfoTooltip
                    isOpen={isInfoToolOpen} // управлять открытием
                    isClose={closeInfotool}
                    text={isTextMassageInfoTool} // передать текст
                    showButton={false} // показывать кнопки или нет
                    backgoundColor={colorBackgroundForm}
                />
                : <></>}
            {/* ИНФОРМАЦИЯ О КЛИЕНТЕ */}
            <ClientInfo
                fullInfoClient={true} // true - полная информация по абонементу, false -краткая информация по клиенту ( только имя, телефон и индикатор абонемента)
                nameClient={nameClient}
                surnameClient={surnameClient}
                textColor={styleText}
                backgroundColor={backgroundColor}
                phoneNumberClient={phoneNumberClient}
                abonementClientFlexGroupe={abonementClientFlexGroupe}
                abonementClientFlexPersonal={abonementClientFlexPersonal}
                abonementClientDarkGroupe={abonementClientDarkGroupe}
                abonementClientDarkPersonal={abonementClientDarkPersonal}
                isShowAllAbonements={true} // показывать инфу о всех абонементах или только о заведенных в базу ( true - всех, false - заведенных в базу)
                infoFlex={infoFlex} // давать ли информацию по абонементам  Flex
                infoDark={infoDark} // давать ли информацию по абонементам  Dark
                client={true} // есть ли клиент в БД, true или false (работа с полученным списком из БД)
                admin={true} // страница админа
                clickOnAbonement={showabonementClient} // функция клика на абонемент
                clickOnClient={openComments} // функция клика на клиента
                clickInfo={true} // true - работает клик на абонемент и имя в качестве кнопки
            />
            {/* ФОРМА ДОБАВЛЕНИЯ ПЕРСОНАЛЬНОЙ ТРЕНИРОВКИ */}
            {isClickAddClientOnPersonal ?
                <FormAddTrain
                    isOpen={isClickAddClientOnPersonal} // управлять открытием
                    isClose={() => { handleIsClickAddClientOnPersonal(false) }} // функция сворачивания формы
                    colorBackground={colorBackgroundForm}
                    onSubmit={handleAddPersonalTrainSubmit}
                    isSuperAdmin={false} // Возможность добавлять группы только у суперадмина
                    trainers={trainers} // массив тренеров для создания персональной тренировки и выбора тренера
                    isInputPersonal={true} // true - показывать возможность выбора персональных тренировок при создании 
                    isCheckGroup={false} // false - работа только с персональными
                    isTypeSpace={isTypeSpace} // выбор зала, true - добавить выбор
                    isAboutClientPage={true} // true без выбора клиента
                />
                : <></>}
            {/* ЗАПИСЬ НА ГРУППОВУЮ ТРЕНИРОВКУ */}
            {activeTrainsTimeTable ?
                <>
                    <TimeTable
                        api={api} // передать АПИ студии из компонента студии в которое монтируется расписание
                        idClient={isIdClient} // передать Id клиента для перечи дальше и работы с тренировками (добавить, отменить запись)
                        styleText={styleText}
                        titleTimeTable={"Запись на группу"} // заголовок таблицы
                        timeTableBackgroundColor={backgroundColor} // цвет фона таблицы
                        loaderTimeTable={loaderTimeTable} // лоадер пока приходят тренировки с АПИ ( true или false )
                        timetableButtonStyle={backgroundColor} // цвет фона кнопок переключения недели
                        trains={groupeTrains} // массив тренировок 
                        setTrains={handleSetTrains} // менять стейт массива тренировок в компоненте студии в которое монтируется расписание
                        statePage={true} //какая страница открыта ( общая или личная) t - общая, f - личная, передается дальше, чтобы отображать тренировки на день либо только те, где записан клиент, либо все на день
                        client={true} // подразумевается, что клиент при работа на странице клиента существует в базе, нужно для записи на тренировку через расписание
                        disabledButtonTrain={false} // работает ли клик на тренировку или нет, true - не работает, нужно для отображения компонента списка посещений
                        setIsChangeInfo={handleChangeAbonement} // мгновенно отрисовать изменения в абонементе на странице
                        isAdmin={true} // true - страница админа, false - клиента, используется для записи на разовые тренировки
                        timetableWeekButtonStyle={timetableWeekButtonStyle} // стиль кнопок перелк дней недели
                        isTypeSpace={infoDark} // показывать ли номер зала - true показывать
                    />
                </>
                : <></>
            }
            {/* СПИСОК АКТИВНЫХ ЗАПИСЕЙ НА ТРЕНИРОВКУ */}
            {activeTrainingSessions ?
                // <></>
                <TimeTable
                    api={api} // передать АПИ студии из компонента студии в которое монтируется расписание
                    idClient={isIdClient} // передать Id клиента для перечи дальше и работы с тренировками (добавить, отменить запись)
                    styleText={styleText}
                    titleTimeTable={"Активные записи на тренировки"} // заголовок таблицы
                    timeTableBackgroundColor={backgroundColor} // цвет фона таблицы
                    loaderTimeTable={loaderTimeTable} // лоадер пока приходят тренировки с АПИ ( true или false )
                    timetableButtonStyle={backgroundColor} // цвет фона кнопок переключения недели
                    trains={activeTrains} // массив тренировок 
                    setTrains={handleSetActiveTrains} // менять стейт массива тренировок в компоненте студии в которое монтируется расписание
                    statePage={false} //какая страница открыта ( общая или личная) t - общая, f - личная, передается дальше, чтобы отображать тренировки на день либо только те, где записан клиент, либо все на день
                    setIsChangeInfo={handleChangeAbonement} // мгновенно отрисовать изменения в абонементе на странице
                    isAdmin={true}
                    timetableWeekButtonStyle={timetableWeekButtonStyle}
                    isTypeSpace={infoDark} // показывать ли номер зала - true показывать
                />
                : <></>}
            {/* ИСТОРИЯ ПОСЕЩЕНИЙ */}
            {activeTrainsTimeTable ?
                < TimeTable
                    api={api} // передать АПИ студии
                    trains={listOfVisits} // массив тренировок
                    setTrains={handleSetTrains} // менять стейт массива тренировок
                    styleText={styleText}
                    titleTimeTable={"История посещений"} // заголовок расписания
                    timetableButtonStyle={"timetable__history-list-text-style"} // стиль кнопки переключения недели
                    timeTableBackgroundColor={backgroundColor}
                    idClient={isIdClient} // айди клиента
                    timetableWeekButtonStyle={timetableWeekButtonStyle} // цвет фона кнопок дней недели
                    disabledButtonTrain={true} // работает ли клик на тренировку или нет, true - не работает, нужно для отображения компонента списка посещений
                    timetableListTextStyle={'timetable__history-list-text-style'} // стиль для текста в истории посещений
                    setIsChangeInfo={handleChangeAbonement} // мгновенно отрисовать изменения в абонементе на странице
                    isTypeSpace={infoDark} // показывать ли номер зала - true показывать
                />
                : <></>}
            {/* ИСТОРИЯ АБОНЕМЕНТОВ */}
            {isClientHystoryAbonement ?
                <HystoryAbonementClient
                    styleText={styleText}
                    hystoryAbonements={hystoryAbonement} // массив истории абонементов
                />
                : <></>
            }
            {/* АБОНЕМЕНТ(Создать или редактировать) */}
            {!isInfoToolOpen && showFormAbonement ?
                <FormAddAbonement
                    onSubmit={abonementClient === undefined || abonementClient === null ? handleSubmitFormAddAbonement : handleSubmitFormUpdateAbonement} // сабмит формы
                    colorBackground={colorBackgroundForm} // цвет фона
                    colorButtonSubmit={colorButtonSubmit} // стиль кнопки сабмита
                    textButtonSubmit={abonementClient === undefined || abonementClient === null ? "Создать" : "Новый"} // текст кнопки сабмита
                    text={abonementClient === undefined || abonementClient === null ? `Первый Абонемент  ${optionAbonement}` : `Новый Абонемент  ${optionAbonement}`} // заголовок формы
                    abonementClient={abonementClient} // передать данные из абонемента для заполнения инпутов
                    nameClient={`${nameClient} ${surnameClient}`} // передать имя клиента в форму ( для информации)
                    isCheckGroup={isCheckGroup} // свитчер для отслеживания работы с абонементом
                    isOpen={showFormAbonement} // true - открыть
                    isClose={closeSubmitFormAddAbonement} // свернуть
                    abonementCosts={abonementCosts} // стоимость абонементов
                />
                : <></>}
            {/* КОММЕНТАРИИ О КЛИЕНТЕ */}
            {isComments ?
                <CommentsTable
                    styleText={styleText}
                    api={api} // передать АПИ студии из компонента студии в которое монтируется расписание
                    titleTimeTable={'Комментарии'} // заголовок таблицы
                    backgroundColor={backgroundColor} // цвет фона таблицы
                    comments={commentsClient}// массив тренировок в расписание из компонента студии в которое монтируется расписание
                    idClient={isIdClient} // айди клиента
                    styleButton={styleButton} // стиль кнопок
                    setComments={addCommentClient} // отрисовать новый комментарий
                />
                : <></>
            }
        </div>
    )
}

export default AboutClientPage;