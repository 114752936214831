
import React, { useState, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import apiAuth from './../../utils/ApiAuth'; // Апи авторизации
//Главная страница
import MainPage from './../MainPage/MainPage'; // выбор страницы Dark или Flex
//Flex
import Flex from '../Flex/Flex'; // страница Flex
import AdminPageFlex from '../AdminPageFlex/AdminPageFlex'; //страница администратора Flex
import SuperAdminFlex from '../SuperAdminFlex/SuperAdminFlex'; // страница супер администратора
import TrainerPageFlex from '../TrainerPageFlex/TrainerPageFlex';
//Dark
import Dark from '../Dark/Dark'; // страница Dark
import TrainerPageDark from '../TrainerPageDark/TrainerPageDark'; // страница тренера Dark
import AdminPageDark from '../AdminPageDark/AdminPageDark'; // страница админа
import SuperAdminDark from '../SuperAdminDark/SuperAdminDark';
//Инфотул
import InfoTooltip from "../InfoTooltip/InfoTooltip"; // компонент инфолог
//Страница не найдена
import NotFoundPage from '../NotFoundPage/NotFoundPage';
//Страница об информации о необходмиости поменять или обновить браузер
import NeedBrowserUpdatePage from '../NeedBrowserUpdatePage/NeedBrowserUpdatePage';

function App() {
  //Авторизация
  const [isAuthClient, setIsAuthClient] = useState(false); // проверить авторизован ли пользователь для защиты путей 
  const [isAuthTrainer, setIsAuthTrainer] = useState(false); // проверить авторизован ли пользователь для защиты путей 
  const [isAuthAdmin, setIsAuthAdmin] = useState(false); // проверить авторизован ли пользователь для защиты путей 
  const [isAuthSuperAdmin, setIsAuthSuperAdmin] = useState(false); // проверить авторизован ли пользователь для защиты путей 
  const [clientTrains, setClientTrains] = useState([]); // массив тренировок (активных записей клиента на тренировки)
  const [isSameSite, setIsSameSite] = useState(true); // проверить поддерживает ли сайт Same Site
  const [loaderTimeTable, setLoaderTimeTable] = useState(false); // идет загрузка расписания - отобразить лоадер
  const [isInfoToolOpen, setIsInfoToolOpen] = useState(false); // стейт для открытия информационного окна
  const [isTextMassageInfoTool, setTextMassageInfoTool] = useState(''); // текс в инфотул
  const [showButtonsInfoTool, setShowButtonsInfoTool] = useState(false); // управлять отображением кнопок в инфотул 
  const [isClickYesOnInfoTool, setIsClickYesOnInfoTool] = useState(false); // кликнута ли кнопка да в инфотул
  const [isClickNoOnInfoTool, setIsClickNoOnInfoTool] = useState(false); // кликнута ли кнопка нет в инфотул


  //Проверить поддерживает ли Same Site бразуер
  function isSameSiteSupported() {
    const userAgent = navigator.userAgent;

    // Проверка версии браузера Chrome
    const chromeVersionMatch = userAgent.match(/Chrome\/(\d+)/);
    if (chromeVersionMatch && parseInt(chromeVersionMatch[1]) < 53) {
      return false;
    }

    // Проверка версии браузера Safari
    const safariVersionMatch = userAgent.match(/Version\/(\d+)/);
    if (safariVersionMatch && parseInt(safariVersionMatch[1]) < 12) {
      return false;
    }

    // Проверка версии браузера Mozilla Firefox
    const firefoxVersionMatch = userAgent.match(/Firefox\/(\d+)/);
    if (firefoxVersionMatch && parseInt(firefoxVersionMatch[1]) < 60) {
      return false;
    }

    // Проверка версии браузера Internet Explorer
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    if (isIE11) {
      return false;
    }

    // Все проверки пройдены, SameSite поддерживается
    return true;
  }

  //АВТОРИЗАЦИЯ
  function handleIsAuthClient(state) { // управлять авторизацией клиента
    setIsAuthClient(state)
  }

  function getUploaderTimeTeble(state) {  // пробросить стейт лоадера загрузки таблицы
    setLoaderTimeTable(state)
  }

  // работа с Инфотулом
  function closeInfoTool() { // свернуть инфотул
    setIsInfoToolOpen(false);
    setTextMassageInfoTool('')
  }

  function handleCloseInfoTool(e) { // закрыть инфотул на оверлей или эск 
    if (e.key === "Escape" || e.target.classList.contains('info-tooltip__overlay')) {
      closeInfoTool(e);
    }
  }

  function handleClickYesInfoTool() {
    setIsClickYesOnInfoTool(true);

  }

  function handleClickNoInfoTool() {
    setIsClickNoOnInfoTool(true);
  }

  function handleShowButtonsInfoTool() {
    setShowButtonsInfoTool(true);
  }

  function handleOpenInfoTool(state) { // управлять открытием infoTool
    setIsInfoToolOpen(state);
  }

  function handleTextInfoTool(text) {
    setTextMassageInfoTool(text);
  }

  useEffect(() => { // слушатели на закрытие инфотул // свернуть на esc или клик на оверлей
    if (isInfoToolOpen) {
      document.addEventListener('click', handleCloseInfoTool);
      document.addEventListener('keydown', handleCloseInfoTool);
    } else {
      document.removeEventListener('click', handleCloseInfoTool);
      document.removeEventListener('keydown', handleCloseInfoTool);
    }
  }, [isInfoToolOpen])

  return (
    <main className="app">
      {isInfoToolOpen ?
        <InfoTooltip
          isOpen={isInfoToolOpen}
          text={isTextMassageInfoTool}
          isClose={closeInfoTool}
          showButton={showButtonsInfoTool}
          clickYes={handleClickYesInfoTool}
          clickNo={handleClickNoInfoTool}
        />
        : <></>}
      <Routes>
        <Route path="/" element={<MainPage />} />

        {/* страница супер администратора Flex*/}
        <Route path="/flex" element={isSameSite ?
          <SuperAdminFlex />
          : <Navigate to='/needbrowserupdatepage' replace />
        } />

        {/* страница супер администратора Dark*/}
        <Route path="/dark" element={isSameSite ?
          <SuperAdminDark />
          : <Navigate to='/needbrowserupdatepage' replace />
        } />

        {/* Not found page */}
        <Route path="/*" element={<NotFoundPage />} />

        {/* Need Browser Update Page */}
        <Route path="/needbrowserupdatepage" element={<NeedBrowserUpdatePage />} />

      </Routes>
    </main>
  );
}

export default App; 
